import Badge from "components/Badge";
import { formatDate } from "helpers/date";
import useClient from "hooks/useClient";
import Link from "next/link";
import IconClock from "public/images/icons/clock.svg";
import IconLocation from "public/images/icons/location.svg";
import type { ActivityCurrent } from "types/model/activity";
import { OverallAvailability } from "types/model/activity";

export const ActivitySessionListItem = ({
  activity,
  isAddedToBasket,
  isIframe,
  overallAvailability
}: {
  activity: ActivityCurrent;
  isAddedToBasket: boolean;
  isIframe: boolean;
  overallAvailability?: OverallAvailability;
}) => {
  const clientQueryInfo = useClient();

  return clientQueryInfo.data ? (
    <li data-cy="activity-list-item">
      <Link
        href={`/activity/${activity.activityGroupId}`}
        passHref
        className="block hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
        target={isIframe ? "_blank" : "_self"}
      >
        <div className="px-4 py-4 sm:px-6">
          <div className="flex items-start justify-between">
            <div className="text-sm font-medium text-indigo-600">
              {activity.title}
              {activity.session && (
                <span
                  data-cy="session-number"
                  className="ml-2 inline-flex rounded-full bg-blue-50 px-2 text-xs font-semibold leading-5 text-blue-700"
                >
                  {activity.session}
                </span>
              )}
            </div>
            <div className="ml-2 flex flex-shrink-0">
              {isAddedToBasket && (
                <div className="mr-2 text-gray-500">
                  <svg
                    className="h-5 w-5"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path>
                  </svg>
                </div>
              )}
              {overallAvailability === OverallAvailability.SoldOut ? (
                <Badge cy="availability-badge" color="red">
                  Sold out
                </Badge>
              ) : overallAvailability ===
                OverallAvailability.NoTicketsAvailable ? (
                <Badge cy="availability-badge" color="gray">
                  Unavailable
                </Badge>
              ) : overallAvailability === OverallAvailability.Available ? (
                <Badge cy="availability-badge" color="green">
                  Available
                </Badge>
              ) : (
                <div className="animate-pulse">
                  <div
                    className="h-4 w-5/6 rounded-full bg-gray-300"
                    style={{ width: 71 }}
                  ></div>
                </div>
              )}
            </div>
          </div>
          <div className="mt-2 sm:flex sm:justify-between">
            <div className="sm:flex">
              <div className="mr-6 flex items-center text-sm text-gray-500">
                <IconClock
                  width={20}
                  height={20}
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                />
                {formatDate(
                  activity.startDate,
                  "h.mma",
                  clientQueryInfo.data.timeZone
                ).toLowerCase()}
                {activity.endDate &&
                  ` - ${formatDate(
                    activity.endDate,
                    "h.mma",
                    clientQueryInfo.data.timeZone
                  ).toLowerCase()}`}
              </div>
              {activity.venue && (
                <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                  <IconLocation
                    width={20}
                    height={20}
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  />
                  {activity.venue}
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </li>
  ) : null;
};
